import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Heading,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import ApiResponse from "../../../services/ApiResponse";
import AdminDashboard from "../AdminDashboard ";
import { getDecodedToken } from "../../../services/Token";
import {
  Carrera,
  CarreraCreate,
  Modalidad,
  Turno,
  Tipo

} from "../../../services/models/Carrera";
import handleApiError from "../../../utils/ApiErrorHandler";
import { ApiValidationResponse } from "../../../services/models/ApiValidationResponse";

const CarreraAddPage = () => {
  const navigate = useNavigate();
  const decoded = getDecodedToken();

  const location = useLocation();
  const carreraToEdit = location.state ? (location.state as Carrera) : null;
  let storedDate = "";
  let storedDateFinalizacion = "";
  let storedDateCierre = "";
  let storedTime = "";

  if (carreraToEdit) {
    storedDate = carreraToEdit.fecha_inscripcion
      ? carreraToEdit.fecha_inscripcion.split("T")[0]
      : "";

    storedTime = carreraToEdit.fecha_inscripcion
      ? carreraToEdit.fecha_inscripcion.split("T")[1].substring(0, 5)
      : "00:00";
      
      storedDateFinalizacion = carreraToEdit.fecha_finalizacion_inscripcion
      ? carreraToEdit.fecha_finalizacion_inscripcion.split("T")[0]
      : "";

      storedDateCierre = carreraToEdit.fecha_inicio_cursado
      ? carreraToEdit.fecha_inicio_cursado.split("T")[0]
      : "";
      
      
  }

  const toast = useToast();

  const nombreRef = useRef<HTMLInputElement>(null);
  const tipoRef = useRef<HTMLSelectElement>(null);
  //const turnoRef = useRef<HTMLSelectElement>(null);
  const descripcionRef = useRef<HTMLInputElement>(null);
  const planDeEstudioRef = useRef<HTMLTextAreaElement>(null);
  const modalidadRef = useRef<HTMLSelectElement>(null);
  const cupoRef = useRef<HTMLInputElement>(null);
  const duracionAniosRef = useRef<HTMLInputElement>(null);
  const duracionMesesRef = useRef<HTMLInputElement>(null);
  const fechaInscripcionRef = useRef<HTMLInputElement>(null);
  const fechaFinalizacionInscripcionRef = useRef<HTMLInputElement>(null);
  const fechaInicioDeCursadoRef = useRef<HTMLInputElement>(null);
  const horaInscripcionRef = useRef<HTMLInputElement>(null);
  const inscripcionRef = useRef<HTMLInputElement>(null);
  const prioridadRef = useRef<HTMLSelectElement>(null);
  

/*   const [prioridad, setPrioridad] = useState<Prioridad>(Prioridad.Alta); */
  const [modalidad, setModalidad] = useState<Modalidad>(Modalidad.Presencial);
  const [tipo, setTipo] = useState<Tipo>(Tipo.Tecnicatura);
  const [turno, setTurno] = useState<Turno>(Turno.Mañana);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const duracionAnios = duracionAniosRef.current?.value
    ? parseInt(duracionAniosRef.current.value)
    : 0;
  const duracionMeses = duracionMesesRef.current?.value
    ? parseInt(duracionMesesRef.current.value)
    : 0;
    console.log("AÑOSSS ",duracionAnios,duracionMeses);
    if (duracionAnios === 0 && duracionMeses === 0) {
      toast({
        title: 'Error de validación',
        description: 'Debe completar al menos Duración en Años o Duración en Meses.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return; // Evita el envío del formulario
    }

    const fechaInscripcion = fechaInscripcionRef.current?.value;
   // const hora = horaInscripcionRef.current?.value || "00:00";
    const fechaHora = new Date(`${fechaInscripcion}`);

    const formattedFechaHora = `${fechaHora.getFullYear()}-${(
      fechaHora.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fechaHora
      .getDate()
      .toString()
      .padStart(2, "0")} ${fechaHora
      .getHours()
      .toString()
      .padStart(2, "0")}:${fechaHora
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${fechaHora.getSeconds().toString().padStart(2, "0")}`;

      const fechaFinalizacion = fechaFinalizacionInscripcionRef.current?.value;
   // const hora = horaInscripcionRef.current?.value || "00:00";
    const fechaHoraFinalizacion = new Date(`${fechaFinalizacion}`);

    const formattedFechaHoraFinalizacion = `${fechaHoraFinalizacion.getFullYear()}-${(
      fechaHoraFinalizacion.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fechaHoraFinalizacion
      .getDate()
      .toString()
      .padStart(2, "0")} ${fechaHoraFinalizacion
      .getHours()
      .toString()
      .padStart(2, "0")}:${fechaHoraFinalizacion
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${fechaHoraFinalizacion.getSeconds().toString().padStart(2, "0")}`;

      const fechaInicioCursado = fechaInicioDeCursadoRef.current?.value;
   // const hora = horaInscripcionRef.current?.value || "00:00";
    const fechaHoraCursado = new Date(`${fechaInicioCursado}`);

    const formattedFechaHoraInicio = `${fechaHoraCursado.getFullYear()}-${(
      fechaHoraCursado.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fechaHoraCursado
      .getDate()
      .toString()
      .padStart(2, "0")} ${fechaHoraCursado
      .getHours()
      .toString()
      .padStart(2, "0")}:${fechaHoraCursado
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${fechaHora.getSeconds().toString().padStart(2, "0")}`;
    const formData: CarreraCreate = {
      nombre: nombreRef.current?.value || "",
      tipo: tipo,
      turno:turno,
      descripcion: descripcionRef.current?.value || undefined,
      plan_de_estudio: planDeEstudioRef.current?.value || undefined,
      modalidad: modalidad,
      cupo: cupoRef.current?.value
        ? parseInt(cupoRef.current.value)
        : undefined,
      duracion_anios: duracionAniosRef.current?.value
        ? parseInt(duracionAniosRef.current.value)
        : 0,
      duracion_meses: duracionMesesRef.current?.value
        ? parseInt(duracionMesesRef.current.value)
        : 0,
      fecha_inscripcion: formattedFechaHora,
      fecha_finalizacion_inscripcion: formattedFechaHoraFinalizacion,
      fecha_inicio_cursado: formattedFechaHoraInicio,
      inscripcion: inscripcionRef.current?.value || undefined,
      institucion_id:
        decoded && decoded.institucion_id !== undefined
          ? decoded.institucion_id
          : 0,
      prioridad: prioridadRef.current?.value
        ? parseInt(prioridadRef.current.value)
        : 0,
    };

    const apiResponse = new ApiResponse<Carrera>();
    carreraToEdit
      ? await apiResponse.useFetch(
          `carreras/${carreraToEdit.id}`,
          "PATCH",
          formData
        )
      : await apiResponse.useFetch("carreras", "POST", formData);

    if (!apiResponse.error) {
      toast({
        title: "Carrera creada.",
        description: "La carrera ha sido creada exitosamente.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/dashboard/carreras");
    } else {
      if (
        typeof apiResponse.error === "object" &&
        apiResponse.error !== null &&
        "errors" in apiResponse.error
      ) {
        handleApiError(apiResponse.error as ApiValidationResponse, toast);
      } else {
        toast({
          title: "Error al actualizar la carrera.",
          description:
            apiResponse.error ||
            "Ocurrió un problema al actualizar la carrera.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box maxW="md" mx="auto" mt={8} p={6} borderWidth={1} borderRadius="lg">
      <Heading mb={6} textAlign="center" size="lg">
        {carreraToEdit ? "Editar Carrera" : "Crear Carrera"}
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={5}>
          <FormControl id="nombre" isRequired>
            <FormLabel>Nombre</FormLabel>
            <Input
              ref={nombreRef}
              defaultValue={carreraToEdit ? carreraToEdit.nombre : ""}
            />
          </FormControl>

          <FormControl id="tipo" isRequired>
            <FormLabel>Tipo</FormLabel>
            <Select
              ref={tipoRef}
              value={tipo}
              defaultValue={
                carreraToEdit ? (carreraToEdit.tipo as Tipo) : ""
              }
              onChange={(e) => setTipo(e.target.value as Tipo)}
            >
             <option value={Tipo.Tecnicatura}>Tecnicatura</option>
                <option value={Tipo.Profesorado}>Profesorado</option>
                <option value={Tipo.Formacion}>Formación profesional</option>
                <option value={Tipo.Licenciatura}>Licenciatura</option>
                <option value={Tipo.Diplomatura}>Diplomatura</option>
                <option value={Tipo.Maestria}>Maestría</option>
                <option value={Tipo.Otra}>Otra</option>
            </Select>
          </FormControl>

          <FormControl id="descripcion">
            <FormLabel>Descripción</FormLabel>
            <Input
              ref={descripcionRef}
              defaultValue={carreraToEdit ? carreraToEdit.descripcion : ""}
            />
          </FormControl>

          <FormControl id="plan_de_estudio">
  <FormLabel>Plan de Estudio</FormLabel>
  <Textarea
    ref={planDeEstudioRef}
    defaultValue={carreraToEdit ? carreraToEdit.plan_de_estudio : ""}
    rows={6} // Puedes ajustar el número de filas visibles según tus necesidades
    resize="vertical" // Permite que el usuario ajuste el tamaño verticalmente
  />
</FormControl>

          <FormControl id="modalidad" isRequired>
            <FormLabel>Modalidad</FormLabel>
            <Select
              ref={modalidadRef}
              value={modalidad}
              defaultValue={
                carreraToEdit ? (carreraToEdit.modalidad as Modalidad) : ""
              }
              onChange={(e) => setModalidad(e.target.value as Modalidad)}
            >
              <option value={Modalidad.Presencial}>Presencial</option>
              <option value={Modalidad.Virtual}>Virtual</option>
              <option value={Modalidad.Semipresencial}>Semipresencial</option>
            </Select>
          </FormControl>

          <FormControl id="cupo">
            <FormLabel>Cupo</FormLabel>
            <Input
              ref={cupoRef}
              type="number"
              min={1}
              defaultValue={carreraToEdit ? carreraToEdit.cupo : ""}
            />
          </FormControl>

          <FormControl id="duracion_anios">
            <FormLabel>Duración en Años</FormLabel>
            <Input
              type="number"
              ref={duracionAniosRef}
              defaultValue={carreraToEdit ? carreraToEdit.duracion_anios : ""}
            />
          </FormControl>

          <FormControl id="duracion_meses">
            <FormLabel>Duración en Meses</FormLabel>
            <Input
              type="number"
              ref={duracionMesesRef}
              defaultValue={carreraToEdit ? carreraToEdit.duracion_meses : ""}
            />
          </FormControl>

          <FormControl id="fecha_inscfecha_inscripcionripcion">
            <FormLabel>Fecha de Inscripción</FormLabel>
            <VStack align="start">
              <Input
                type="date"
                ref={fechaInscripcionRef}
                defaultValue={storedDate}
              />
            </VStack>
          </FormControl>

          <FormControl id="fecha_finalizacion_inscripcion">
            <FormLabel>Fecha de Cierre de Inscripción</FormLabel>
            <VStack align="start">
              <Input
                type="date"
                ref={fechaFinalizacionInscripcionRef}
                defaultValue={storedDate}
              />
            </VStack>
          </FormControl>

          <FormControl id="fecha_inicio_cursado">
            <FormLabel>Fecha de Inicio De Cursado</FormLabel>
            <VStack align="start">
              <Input
                type="date"
                ref={fechaInicioDeCursadoRef}
                defaultValue={storedDate}
              />
            </VStack>
          </FormControl>

          <FormControl id="turno" isRequired>
            <FormLabel>Turno de Cursado</FormLabel>
            <Select
              ref={modalidadRef}
              value={modalidad}
              defaultValue={
                carreraToEdit ? (carreraToEdit.turno as Turno) : ""
              }
              onChange={(e) => setTurno(e.target.value as Turno)}
            >
              <option value={Turno.Mañana}>Mañana</option>
              <option value={Turno.Tarde}>Tarde</option>
              <option value={Turno.Noche}>Noche</option>
            </Select>
          </FormControl>

          <FormControl id="inscripcion">
            <FormLabel>Link de Inscripción</FormLabel>
            <Input ref={inscripcionRef} defaultValue="" />
          </FormControl>

          {/*         <FormControl id="prioridad" isRequired>
              <FormLabel>Prioridad</FormLabel>
              <Select 
              ref={prioridadRef} 
              value={prioridad} 
              onChange={(e) => setPrioridad(parseInt(e.target.value) as Prioridad)}>
                <option value={Prioridad.Alta}>Alta</option>
                <option value={Prioridad.Media}>Media</option>
                <option value={Prioridad.Baja}>Baja</option>
              </Select>
            </FormControl> */}

          <Button type="submit" colorScheme="teal" size="lg" width="full">
            {carreraToEdit ? "Guardar Cambios" : "Crear Carrera"}
          </Button>
          <Button
            type="button"
            onClick={() => navigate("/dashboard/carreras")}
            colorScheme="gray"
            size="lg"
            width="full"
          >
            Cancelar
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default CarreraAddPage;
