export enum Modalidad {
  Presencial = 'Presencial',
  Virtual = 'Virtual',
  Semipresencial = 'Semipresencial',
}
export enum Tipo {
  Tecnicatura = 'Tecnicatura',
  Profesorado = 'Profesorado',
  Formacion = 'Formación profesional',
  Licenciatura='Licenciatura', 
  Diplomatura='Diplomatura', 
  Maestria='Maestría', 
  Otra='Otra'
}

export enum Turno {
  Mañana = 'Mañana',
  Tarde = 'Tarde',
  Noche = 'Noche',
}


export interface Carrera {
  id: number;                       
  nombre: string;                  
  tipo: Tipo;      
  turno: Turno;            
  descripcion?: string;           
  plan_de_estudio?: string;      
  modalidad: Modalidad; 
  cupo?: number;                  
  duracion_anios: number;          
  duracion_meses: number;          
  fecha_inscripcion: string;  
  fecha_finalizacion_inscripcion: string;   
  fecha_inicio_cursado: string;         
  inscripcion?: string;           
  institucion_id: number;        
  estado: number;                  
  prioridad: number;              
  institucion_nombre?: string;    
  institucion_direccion?: string; 
  institucion_tel?: string;      
  institucion_pagina?: string;   
  institucion_logo?: string;
}

export type CarreraCreate = Omit<Carrera, 'id' | 'estado'>;

export interface CarreraUpdate {
  nombre?: string;                  
  tipo?: Tipo;                
  descripcion?: string;           
  plan_de_estudio?: string;      
  modalidad?: Modalidad; 
  cupo?: number;                  
  duracion_anios?: number;          
  duracion_meses?: number;          
  fecha_inscripcion?: Date;        
  inscripcion?: string;           
  prioridad?: number;              
}